var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(
      _vm.campaigns.filter(
        function (campaign) { return campaign.display_location === 'store-index-top'; }
      ).length
    )?_c('CampaignsSlideshow',{attrs:{"ratio":"1800:600","campaigns":_vm.campaigns.filter(
        function (campaign) { return campaign.display_location === 'store-index-top'; }
      ),"isLoading":_vm.isLoading}}):_vm._e(),_c('div',{staticClass:"uk-text-large",attrs:{"test":"store-index-title"}},[_vm._v(" Productos Destacados ")]),(_vm.isLoading)?_c('div',{staticClass:"uk-text-center"},[_c('div',{attrs:{"uk-spinner":""}})]):_vm._e(),(!_vm.isLoading && _vm.featuredProducts)?_c('div',{staticClass:"\n      uk-margin-small-top\n      uk-child-width-1-2@s\n      uk-child-width-1-3@m\n      uk-child-width-1-4@l\n      uk-grid-medium\n      uk-grid-match\n    ",attrs:{"uk-grid":""}},[_vm._l((_vm.displayFeaturedProducts.slice(
        0,
        Math.floor(_vm.displayFeaturedProducts.length / 2)
      )),function(product){return _c('div',{key:product.id},[_c('ProductCard',{attrs:{"product":product,"setActive":_vm.setActive,"showQuickview":false}})],1)}),(
        _vm.campaigns.filter(
          function (campaign) { return campaign.display_location === 'store-index-middle'; }
        ).length
      )?_c('CampaignsSlideshow',{staticClass:"uk-width-1-1",attrs:{"ratio":"1800:427.5","campaigns":_vm.campaigns.filter(
          function (campaign) { return campaign.display_location === 'store-index-middle'; }
        ),"isLoading":_vm.isLoading}}):_vm._e(),_vm._l((_vm.displayFeaturedProducts.slice(
        Math.floor(_vm.displayFeaturedProducts.length / 2)
      )),function(product){return _c('div',{key:product.id},[_c('ProductCard',{attrs:{"product":product,"setActive":_vm.setActive,"showQuickview":false}})],1)}),(
        _vm.campaigns.filter(
          function (campaign) { return campaign.display_location === 'store-index-bottom'; }
        ).length
      )?_c('CampaignsSlideshow',{staticClass:"uk-width-1-1",attrs:{"ratio":"1800:427.5","campaigns":_vm.campaigns.filter(
          function (campaign) { return campaign.display_location === 'store-index-bottom'; }
        ),"isLoading":_vm.isLoading}}):_vm._e()],2):_vm._e(),_c('ProductQuickview',{attrs:{"product":_vm.activeProduct}}),_c('PopupModal',{attrs:{"popups":_vm.popups,"isLoading":_vm.isLoading}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }