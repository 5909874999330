<template>
  <div
    v-if="!isLoading && campaigns.length !== 0"
    :uk-slideshow="
      'animation: pull; autoplay: true; autoplay-interval: 6000; ratio: ' +
      ratio +
      ';'
    "
  >
    <div
      class="
        uk-position-relative
        uk-visible-toggle
        uk-border-rounded
        uk-overflow-hidden
      "
      tabindex="-1"
    >
      <ul class="uk-slideshow-items" v-if="campaigns.length !== 0">
        <li v-for="campaign in campaigns" :key="campaign.id">
          <router-link :to="'/' + campaign.slug" class="uk-link-reset">
            <img :src="campaign.image" alt width="100%" />
            <div
              class="uk-position-top-left uk-overlay uk-overlay-default"
              v-if="campaign.display_dates"
            >
              Del {{ formatDate(campaign.available_from) }} al
              {{ formatDate(campaign.available_to) }}
            </div>
          </router-link>
        </li>
      </ul>

      <a
        class="uk-position-center-left uk-position-small uk-hidden-hover"
        href="#"
        uk-slidenav-previous
        uk-slideshow-item="previous"
      ></a>
      <a
        class="uk-position-center-right uk-position-small uk-hidden-hover"
        href="#"
        uk-slidenav-next
        uk-slideshow-item="next"
      ></a>
    </div>

    <ul class="uk-slideshow-nav uk-dotnav uk-flex-center uk-margin"></ul>
  </div>
</template>

<script>
export default {
  name: "CampaignsSlideshow",

  props: ["campaigns", "isLoading", "ratio"],
};
</script>