var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"uk-flex-top uk-modal-container",attrs:{"id":"quickview-modal","uk-modal":""}},[_c('div',{key:'product-' + (_vm.product ? _vm.product.id : ''),staticClass:"\n      uk-modal-dialog uk-modal-body uk-margin-auto-vertical uk-border-rounded\n    "},[_c('button',{staticClass:"uk-modal-close-default",attrs:{"type":"button","uk-close":""}}),(_vm.product)?_c('div',{key:'product-' + _vm.product.id},[_c('div',{staticClass:"uk-flex"},[_c('div',{staticClass:"uk-width-1-3"},[_c('img',{staticClass:"uk-border-rounded",attrs:{"src":_vm.product.main_image
                ? _vm.product.main_image.url
                : _vm.api + '/storefronts/' + _vm.storefrontId + '/logo',"alt":""}})]),_c('div',{staticClass:"uk-margin-small-left"},[_c('div',{staticClass:"uk-text-large"},[(
                _vm.storefrontStorefront.type === 'redeem' &&
                _vm.product.storefront_redeemables.length &&
                _vm.product.storefront_redeemables[
                  _vm.product.storefront_redeemables.length - 1
                ].display_name
              )?_c('div',[_vm._v(" "+_vm._s(_vm.product.storefront_redeemables[ _vm.product.storefront_redeemables.length - 1 ].display_name)+" ")]):_c('div',{staticClass:"uk-flex"},[_c('div',[_vm._v(_vm._s(_vm.product.display_name))]),(['administrator'].includes(_vm.authUser.type.slug))?_c('div',[_vm._v(" - "+_vm._s(_vm.product.name)+" ")]):_vm._e(),_c('div',{staticClass:"uk-margin-small-left uk-text-muted"},[_vm._v(" "+_vm._s(_vm.product.display_code)+" ")])])]),(_vm.product.prices)?_c('price-table',{attrs:{"price-tables":_vm.product.prices}}):_vm._e()],1)]),_c('div',{staticClass:"uk-margin-small-top uk-text-center"},[_c('router-link',{staticClass:"uk-button uk-button-primary uk-border-rounded",attrs:{"to":'/store/' + _vm.product.id}},[_vm._v("Ir a detalle")])],1)]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }