<template>
  <div id="quickview-modal" class="uk-flex-top uk-modal-container" uk-modal>
    <div
      :key="'product-' + (product ? product.id : '')"
      class="
        uk-modal-dialog uk-modal-body uk-margin-auto-vertical uk-border-rounded
      "
    >
      <button class="uk-modal-close-default" type="button" uk-close></button>
      <div v-if="product" :key="'product-' + product.id">
        <div class="uk-flex">
          <div class="uk-width-1-3">
            <img
              class="uk-border-rounded"
              :src="
                product.main_image
                  ? product.main_image.url
                  : api + '/storefronts/' + storefrontId + '/logo'
              "
              alt
            />
          </div>
          <div class="uk-margin-small-left">
            <div class="uk-text-large">
              <div
                v-if="
                  storefrontStorefront.type === 'redeem' &&
                  product.storefront_redeemables.length &&
                  product.storefront_redeemables[
                    product.storefront_redeemables.length - 1
                  ].display_name
                "
              >
                {{
                  product.storefront_redeemables[
                    product.storefront_redeemables.length - 1
                  ].display_name
                }}
              </div>
              <div v-else class="uk-flex">
                <div>{{ product.display_name }}</div>
                <div v-if="['administrator'].includes(authUser.type.slug)">
                  - {{ product.name }}
                </div>
                <div class="uk-margin-small-left uk-text-muted">
                  {{ product.display_code }}
                </div>
              </div>
            </div>
            <price-table v-if="product.prices" :price-tables="product.prices" />
          </div>
        </div>
        <div class="uk-margin-small-top uk-text-center">
          <router-link
            class="uk-button uk-button-primary uk-border-rounded"
            :to="'/store/' + product.id"
            >Ir a detalle</router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PriceTable from "../Utils/PriceTable";
import { mapGetters } from "vuex";

export default {
  name: "ProductQuickview",

  components: {
    PriceTable,
  },

  props: ["product"],

  computed: {
    ...mapGetters(["authUser", "storefrontStorefront"]),
  },
};
</script>