<template>
  <div id="popup-modal" class="uk-flex-top uk-modal-container" uk-modal>
    <div
      v-if="!isLoading"
      class="
        uk-modal-dialog
        uk-margin-auto-vertical
        uk-border-rounded
        uk-overflow-hidden
      "
    >
      <button class="uk-modal-close-default" type="button" uk-close></button>
      <div
        uk-slideshow="animation: pull; autoplay: true; autoplay-interval: 6000; ratio: 1000:500;"
      >
        <div class="uk-position-relative uk-visible-toggle" tabindex="-1">
          <ul class="uk-slideshow-items">
            <li v-for="popup in popups" :key="popup.id">
              <img width="100%" :src="popup.image" alt="" />
              <div
                class="uk-position-top-left uk-overlay uk-overlay-default"
                v-if="popup.hideable"
              >
                <button
                  @click="hidePopup(popup.id)"
                  class="uk-button uk-button-secondary uk-border-rounded"
                >
                  No volver a ver este anuncio
                </button>
              </div>
            </li>
          </ul>
          <div class="uk-position-bottom uk-overlay uk-overlay-default">
            <ul
              class="uk-slideshow-nav uk-dotnav uk-flex-center uk-margin"
            ></ul>
          </div>

          <a
            class="uk-position-center-left uk-position-small uk-hidden-hover"
            href="#"
            uk-slidenav-previous
            uk-slideshow-item="previous"
          ></a>
          <a
            class="uk-position-center-right uk-position-small uk-hidden-hover"
            href="#"
            uk-slidenav-next
            uk-slideshow-item="next"
          ></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PopupModal",

  props: ["popups", "isLoading"],
};
</script>